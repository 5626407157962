import React from 'react';
import PropTypes from 'prop-types';
import { LanguageContext } from "../../../contexts/LanguageContext";
import {Field, useFormikContext} from "formik";

const Input = ({ field, input, errors, touched, setFieldValue }) => {
  // const {values, errors, setFieldValue} = useFormikContext();
  const onChange = event => {
    const { value } = event.target;
    if (input?.type === "tel") {
      const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      if (regex.test(value.toString())) {
        setFieldValue(field, value);
      }
    } else {
      setFieldValue(field, value);
    }
  };

  return (
    <LanguageContext.Consumer>
      {context => {

        return (
          <div className={`input ${errors[field] && touched[field] ? "has-error" : ""}`}>
            <Field type={input?.type || "text"}
                   id={field}
                   name={field}
                   placeholder={context.getTranslate(input.placeholder)}
                   pattern={input?.type === 'tel' ? '[0-9.]+' : undefined}
                   title={input?.type === 'tel' ? 'Only number' : undefined}
                   />
            {input.errorMessage ? <span className={`error`}>{context.getTranslate(input.errorMessage)}</span> : null}
          </div>
        );

      }}
    </LanguageContext.Consumer>
  )

};

Input.defaultProps = {
  placeholder: ""
};

Input.propTypes = {
  field: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};

export default Input;
