import React from 'react';
import {LanguageContext} from "../../../../contexts/LanguageContext";
import Image from "../../../UI/Images/Image";
import "./toggleSlide.scss";

const ToggleSlide = ({ className, slideInfo, index, length, useCounter, placeholder }) => {

    const {image, image_secondary, text_primary, text_secondary} = slideInfo;
    const [active, setActive] = React.useState(false);

    return (
        <LanguageContext.Consumer>
            {context => {return (
                <div className={`picture-slide toggle-slide swiper-slide ${className} ${slideInfo.className || ''}`}
                     style={{ ...slideInfo.style }}>
                    <div className={"wrapper"}>

                        <div className={`toggle ${active ? "active" : ""}`.trim()} onClick={()=>{setActive(!active)}}>
                            <div className={"toggle-bar"}/>
                            <span>{context.getTranslate(active ? text_secondary : text_primary)}</span>
                        </div>
                        {useCounter ? <div className={"counter"}>{index + 1} {context.getTranslate("other.of")} {length}</div> : null}
                        <div className={"images"}>
                            <Image useLazy={false} src={image} placeholder={`placeholders/${placeholder || "1120"}.jpg`}/>
                            <Image className={active ? "active secondary" : "secondary"} useLazy={false} src={image_secondary} placeholder={`placeholders/${placeholder || "1120"}.jpg`}/>
                        </div>
                    </div>
                </div>
            )}}
        </LanguageContext.Consumer>
    );
}


export {ToggleSlide};