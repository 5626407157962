import React from 'react';
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import { Link } from 'react-router-dom';
import {LanguageContext} from "../../contexts/LanguageContext";
import PropTypes from 'prop-types';
import Image from "../../components/UI/Images/Image";
import ToggleLang from "../../components/ToggleLang/ToggleLang";
import "./Header.scss";


const Header = ({ dir, opened, region, menu }) => {

    const [itemMenu, setItemMenu] = React.useState();

    return (
        <LanguageContext.Consumer>

            {context => {
                return (
                    <header dir={dir}>
                        <div className={"container"}>
                            <div className={"menu"}>
                                <Link to={`/${region}`}>
                                    <Image src={"svg/logo.svg"}
                                        useLazy={false}
                                        alt={"Hyundai"}
                                        className={"logo"} />
                                </Link>
                                <div className={"menu-list"}>
                                    {menu && menu.map( item => <LinkScroll activeClass="active"
                                                              to={item.link}
                                                              spy={true}
                                                              smooth={true}
                                                              duration={900}
                                                              offset={20}
                                      className={`menu-item `}>{context.getTranslate(item.text)}</LinkScroll>)}
                                </div>
                            </div>

                            <Image src={"/svg/top.svg"} className={"top-button desktop"} onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth"
                                })
                            }}/>
                            <ToggleLang className={"desktop"} region={region} />
                            <div className={`burger ${opened ? "js-close" : "js-open-menu"}`}>
                                <Image src={`/icons/${opened ? "close" : "burger"}.svg`}/>
                            </div>
                        </div>
                    </header>
                );
            }}
        </LanguageContext.Consumer>
    );

};

Header.propTypes = {};

export default Header;
