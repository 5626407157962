import React, { useContext, useLayoutEffect } from 'react';
import Parser from 'html-react-parser';
import Layout from '../../components/Layout/Layout';
import TitleBlock from '../../components/UI/TitleBlock/TitleBlock';
import { LanguageContext } from '../../contexts/LanguageContext';

import './mainDisclaimers.scss';

// disclaimers json
import UAEDisclaimersJSON from '../../content/legalDisclaimers/UAE.json'

const disclaimersByRegion = {
  UAE: UAEDisclaimersJSON,
}

const LegalDisclaimer = ({ match }) => {
  const { region } = match.params;
  const { lang, getTranslate } = useContext(LanguageContext);
  const dir = getTranslate('direction');

  const currentDisclaimers = disclaimersByRegion[region][lang];

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [region]);

  if (!currentDisclaimers) return null;

  const { title, subtitle, disclaimers } = currentDisclaimers;

  return (
    <Layout region={region} hideMenu>
      <main className="main-disclaimers" dir="ltr">
        <div className="container main-disclaimers__container">
          <TitleBlock
            title={title}
            subtitle={subtitle}
          />
        </div>
        {disclaimers.map((disclaimer, n) => (
          <section key={'disclaimer-' + n} className="disclaimer">
            <div className="container disclaimer__container">
              <h2 className="disclaimer__title">{disclaimer.title}</h2>
              <p className="disclaimer__description">{Parser(disclaimer.description)}</p>
            </div>
          </section>
        ))}
      </main>
    </Layout>
  );
};

export default LegalDisclaimer;
