import React, { useContext, useState } from 'react';
import cn from 'classnames';
import Image from '../UI/Images/Image';

import './videos.scss';
import { LanguageContext } from '../../contexts/LanguageContext';

const Videos = ({
  className,
  videoConf,
  onChangeVideo,
  currentVideo,
}) => {
  const { getTranslate, lang } = useContext(LanguageContext);
  const dir = getTranslate('direction');

  return (
    <>
      <div className={cn('videos', className)} dir={dir}>
        {videoConf.map((video, n) => (
          <div className="videos__item" key={'youtube-videos-' + n}>
            <span className="videos__name">{video.name[lang]}</span>
            <button
              className={cn(
                'videos__button',
                { 'videos__button--active': currentVideo === n }
              )}
              onClick={() => onChangeVideo({ key: n })}
            >
              <div className="videos__play-icon">
                <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.41431 1.69437L14.0625 10.8643L1.41431 20.0342L1.41431 1.69437Z" stroke="white"/>
                </svg>
              </div>
              <Image src={video.prevImage} />
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default Videos;
