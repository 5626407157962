import React from 'react';
import Footer from '../../layouts/Footer/Footer';
import Header from '../../layouts/Header/Header';
import PopupMenu from '../Popups/PopupMenu/popupMenu';
import Image from '../UI/Images/Image';
import Sprite from '../UI/SvgSprite/Sprite';

const menu = [
  {
      text: "menu.highlights",
      link: "highlights",
  },
  {
      text: "menu.premium",
      link: "premium",
  },
  {
      text: "menu.wagon",
      link: "wagon",
  },
  {
      text: "menu.van",
      link: "van"
  },
  {
      text: "menu.safety",
      link: "safety",
  },
  {
      text: "menu.gallery",
      link: "gallery",
  }
]

const Layout = ({
  children,
  dir,
  region,
  hideMenu
}) => {
  return (
    <>
      <Sprite />

      <Header dir={dir} region={region} menu={hideMenu ? undefined : menu} />

      <PopupMenu dir={dir} btnOpen={"js-open-menu"} menu={menu} />

      {children}

      <Footer
        region={region}
      />

      <Image
        src={"/svg/top.svg"}
        className={"top-button mobile"}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          })
        }}/>
    </>
  );
};

export default Layout;
