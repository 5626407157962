import React, { useContext } from 'react';
import Image from "../../components/UI/Images/Image";
import {LanguageContext} from "../../contexts/LanguageContext";
import Parser from "html-react-parser";

import "./Footer.scss";
import Tabs from '../../components/Tabs/Tabs';
import { useHistory, useRouteMatch } from 'react-router';

const dillers = {
    Dammam: "desktop/dealer/Dammam.svg",
    Jeddah: "footer.jeddahImage",
    Riyadh: "desktop/dealer/Riyadh.png",
    Egypt: "footer.egyptDealerImage",
    Kuwait: "footer.kuwaitDealerImage",
    Bahrain: "desktop/dealer/Bahrain.svg",
    Morocco:"desktop/dealer/Morocco.svg",
    Qatar:"desktop/dealer/Qatar.svg",
    Oman:"desktop/dealer/Oman.png",
    UAE:"desktop/dealer/UAE.png",
    Jordan:"desktop/dealer/Jordan.png",
};

const tabItems = [
    {
        id: 'legal-disclaimers',
        action: 'redirect',
        name: {
            ar: 'إخلاء المسؤولية القانونية',
            en: 'Legal Disclaimer',
        }
    }
]

const Footer = ({ region }) => {
    const { lang, getTranslate } = useContext(LanguageContext);
    const { push } = useHistory();

    const actionBySelectedTab = (item) => {
        switch (item.action) {
            case 'redirect':
                push(`${process.env.REACT_APP_URL}${region}${lang === 'ar' ? '' : '/' + lang}/${item.id}`)
                break;
            default:
                return;
        }
    }

    return (
        <footer className={"pp-section pp-section--small-padding pp-section-footer"}>
            <div className={"container"}>
                <div className={"logos"}>
                    {dillers[region] &&
                        (<div className={"dealer"}>
                            <Image
                                useLazy={false}
                                className={`rivenditore ${region.toLowerCase()}`}
                                src={getTranslate(dillers[region])}
                                alt={"dealer"}
                            />
                        </div>)
                    }
                    <Image className={"logo"} src={"svg/logo-w.svg"} useLazy={false} alt={"Hyundai"}/>
                </div>
                <Tabs
                    tabItems={tabItems}
                    onClickItem={actionBySelectedTab}
                    lang={lang}
                    hideElement={region === 'UAE' ? undefined : 'legal-disclaimers'}
                />
                <p className={"text"}>
                    All specifications and descriptions provided herein may
                    be different from the actual specifications and
                    descriptions for the product.
                    Hyundai is a registered trademark of Hyundai Motor Company.
                    All rights reserved, Hyundai Motor Middle&nbsp;East&nbsp;&&nbsp;Africa.
                </p>
            </div>
        </footer>
    )
};

export default Footer;
