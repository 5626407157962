import React, { useContext } from 'react';
import TagManager from 'react-gtm-module'
import {LanguageContext} from "../../contexts/LanguageContext";
import Helmet from "react-helmet"
import ReactGA from 'react-ga';

import goodJSON from "../../content/good.json";
import featuresJSON from "../../content/features.json";
import galleyJSON from "../../content/gallery.json";
import learnMoreJSON from "../../content/learnMore.json";
import disclaimers from "../../content/disclaimers.json";
import { buttons, fields, links, locations } from "../../content/regions.json";
import carViewJSON from '../../content/carViewConf.json';

import Hero from "../../layouts/Hero/Hero";
import Gallery from "../../layouts/Gallery/Gallery";
import LearnMore from "../../layouts/LearnMore/LearnMore";
import FormBasic from "../../layouts/FormBasic/FormBasic";
import PopupYoutube from "../../components/Popups/PopupYoutube";
import Disclaimers from "../../layouts/Disclaimers/Disclaimers";
import {MainFeatures} from "../../layouts/MainFeatures/MainFeatures";
import Good from "../../layouts/Good/Good";
import CarView from '../../layouts/CarView/CarView';

import mainSAVideo from '../../assets/video/main_desktop_onlySA.mp4';
import mainLVideo from '../../assets/video/main_desktop_L.mp4';
import { youtubeMainVideos } from '../../content/youtubeVideos';
import Layout from '../../components/Layout/Layout';

const Main = ({match, history}) => {
  const tagManagerArgs = {
    gtmId: 'GTM-WSXB57X'
  };

  const region = match.params.region;

  const { lang, getTranslate } = useContext(LanguageContext);

  const dir =  getTranslate('direction');

  const utms = ['emirates_golf_club', 'dubai_creek_golf', 'jumeirah_golf_estates'];

  React.useLayoutEffect(() => {
    TagManager.initialize(tagManagerArgs);
    const bodyEl = document.querySelector("body")
    let utmCampaignQuery = new URLSearchParams(window.location.search).get("utm_campaign");
    let withUtm = utms.findIndex(utm => utm === utmCampaignQuery) !== -1;
    const href = window.location.href
    if (href.includes('emirates_golf_club') || href.includes('dubai_creek_golf') || href.includes('jumeirah_golf_estates')){
      ReactGA.initialize('UA-196595055-1',{ debug: true,});
    } else {
      ReactGA.initialize(withUtm ? 'UA-196595055-1' : 'UA-192304168-1',{ debug: true,});
    }
    ReactGA.pageview(region);
    const country = ["Jeddah", "Dammam", "Riyadh"].includes(region) ? "Saudi Arabia" : region;
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `<img src="https://ad.doubleclick.net/ddm/activity/src=3220295;type=gquo;cat=hyund0;u1=${window.location.href};u2=${lang};u3=${country};u4=${region};u5=Tucson;u6=none;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?" width="1" height="1" alt=""/>`;
    window.gtag('event', 'conversion', {
      'allow_custom_scripts': true,
      'u1': window.location.href,
      'u2': lang,
      'u3': country,
      'u4': region,
      'u5': 'Tucson',
      'u6': 'none',
      'send_to': 'DC-3220295/gquo/hyund0+unique'
    });
    bodyEl.appendChild(noscript);

    return () => {
      bodyEl.removeChild(noscript);
    }
  });

  const formFields = fields?.[region] || {
    "firstName": {
      value: "",
      placeholder: "inputs.firstName"
    },
    "lastName": {
      value: "",
      placeholder: "inputs.lastName",
    },
    "email": {
      value: "",
      placeholder: "inputs.email",
      type: "email",
    },
    "phone": {
      value: "",
      placeholder: "inputs.mobile",
      type: "tel",
    }
  };

  if (locations?.[region]) {
    !!formFields["city"] ? Object.assign(formFields["city"], {options: locations[region]}) : formFields["city"] = {
      value: "",
      type: "select",
      placeholder: "inputs.location",
      defaultValue: "location",
      options: locations[region]
    };
  }

  const terms = [
    "terms.term_1",
    "terms.term_2",
    "terms.term_3",
    "terms.term_4",
    "terms.term_5",
  ];

  const onFileDownload = () => {
    const noscript = document.createElement("noscript");

    const country = ["Jeddah", "Dammam", "Riyadh"].includes(region) ? "Saudi Arabia" : region;
    noscript.innerHTML=`<img src="https://ad.doubleclick.net/ddm/activity/src=3220295;type=gbro;cat=hyund0;u1=${window.location.href};u2=${lang};u3=${country};u4=${region};u5=Tucson;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?" width="1" height="1" alt=""/>`;
    window.gtag('event', 'conversion', {
      'allow_custom_scripts': true,
      'u1': window.location.href,
      'u2': lang,
      'u3': country,
      'u4': region,
      'u5': 'Tucson',
      'send_to': 'DC-3220295/gbro/hyund0+unique'
    });
    document.querySelector("body").appendChild(noscript);
  }

  const mainBackVideo = region === "South-Africa" ? mainSAVideo : mainLVideo;

  if(region === 'South-Africa' && lang !== 'en') {
    history.push('/South-Africa/en');
  }

  return (
    <Layout
      region={region}
      dir={dir}
    >
      <main className={`pp-page-elantra ${dir} region-${region}`}
          dir={dir}>

      <Helmet>
        <meta property={"og:image"}
              content={`${process.env.REACT_APP_URL}assets/images/opengraph/open_graph.jpg`}/>
      </Helmet>
      <PopupYoutube
        btnOpen={"js-open-youtube"}
        moreVideos={youtubeMainVideos[region] ? youtubeMainVideos[region] : youtubeMainVideos['default']}
      />
      <Hero
        region={region}
        video={mainBackVideo}
        useYoutube={true}
        dir={dir}
      />
      <FormBasic
        fields={formFields}
        lang={lang}
        region={region}
        button={buttons?.[region] || ""}
        dir={dir}
      />

      <Good
        title={goodJSON.title}
        id={"highlights"}
        changeTitle={true}
        className={"centered"}
        slideType={"PictureSlide"}
        sliderClassName={"slim"}
        slideClassName={"white-counter"}
        subtitle={goodJSON.subtitle}
        slides={goodJSON.slides[region]}
        dir={dir}
      />

      <CarView
        id="premium"
        carViewConf={carViewJSON['stariaPremium'][region]}
      />

      <CarView
        id="wagon"
        carViewConf={carViewJSON['stariaWagon'][region]}
        className="gray"
      />

      <CarView
        id="van"
        carViewConf={carViewJSON['stariaVan'][region]}
      />

      <MainFeatures
        id={"safety"}
        dir={dir}
        title={featuresJSON.title}
        textAbove={featuresJSON.categories}
        autoAlign={true}
        slides={featuresJSON.slides[region]}
        smallSlides={featuresJSON.smallSlider[region]}
      />

      <Gallery
        id={"gallery"}
        title={galleyJSON.title}
        mainSlides={galleyJSON.mainSlides[region]}
        thumbSlides={galleyJSON.mainSlides[region]}
        dir={dir}
      />

      <LearnMore
        title={learnMoreJSON.title[region]}
        subtitle={learnMoreJSON.subtitle[region]}
        link={links[region]}
        region={region}
        onFileDownload={onFileDownload}
        btns={learnMoreJSON.btns}
      />

      <Disclaimers region={region} disclaimers={disclaimers[region]}/>
    </main>
    </Layout>
  )
};

Main.propTypes = {};

export default Main;
