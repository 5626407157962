import React from 'react';
import { LanguageContext } from "../../contexts/LanguageContext";
import "./ToggleLang.scss";

const ToggleLang = ({ region }) => {

  return (
    <LanguageContext.Consumer>
      {(context) => {
        context.checkLang();

        return (
          <ul className={`toggle-lang ${context.lang === "ar" ? "active" : ""}`}>
            {(region === 'aloula' || region === 'altsak') && <li onClick={() => context.toggleLang("en")} className={context.lang === "en" ? "active" : ""}>EN</li>}
            {region === 'Morocco'
              ? <li onClick={() => context.toggleLang("fr")} className={context.lang === "fr" ? "active" : ""}>FR</li>
              :  region === 'aloula' || region === 'altsak' ?
                <li onClick={() => context.toggleLang("kr")} className={context.lang === "kr" ? "active" : ""}>KR</li>
              : <li onClick={() => context.toggleLang("en")} className={context.lang === "en" ? "active" : ""}>EN</li>
            }
            { (region !== 'South-Africa') && <li onClick={() => context.toggleLang("ar")} className={context.lang === "ar" ? "active" : ""}>عربي</li> }
          </ul>
        );

      }}
    </LanguageContext.Consumer>
  )

};

ToggleLang.propTypes = {};

export default ToggleLang;
