export const youtubeMainVideos = {
    "default": [
        {
            id: 'vidStar3',
            prevImage: 'preview/staria_premmium_7seater.jpg',
            videoId: '5QLmKNkmYzw',
            name: {
                en: "7 seater",
                ar: "7 مقاعد",
            },
        },
        {
            id: 'vidStar2',
            prevImage: 'preview/staria_premium_9seater.jpg',
            videoId: '9Sw3Cbr3tlo',
            name: {
                en: "9 seater",
                ar: "9 مقاعد",
            },
        },
        {
            id: 'vidStar1',
            prevImage: 'preview/staria_11seater.jpg',
            videoId: 'N1U5-VoRL74',
            name: {
                en: "11 seater",
                ar: "11 مقاعد",
            },
        },
    ],
    "South-Africa": [
        {
            id: 'vidStar3',
            prevImage: 'preview/staria_premmium_7seater_sa.jpg',
            videoId: 'ege0c5GuGIg',
            name: {
                en: "7 seater",
                ar: "7 مقاعد",
            },
        },
        {
            id: 'vidStar2',
            prevImage: 'preview/staria_premium_9seater_sa.jpg',
            videoId: 'rsdYjqkYSGU',
            name: {
                en: "9 seater",
                ar: "9 مقاعد",
            },
        },
        {
            id: 'vidStar1',
            prevImage: 'preview/staria_11seater_sa.jpg',
            videoId: '1GbyTzwsxY4',
            name: {
                en: "11 seater",
                ar: "11 مقاعد",
            },
        },
    ],
}
