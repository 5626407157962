import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Popup from "../Popup";
import Svg from "../../UI/SvgSprite/Svg";

import "./popupMenu.scss";
import "../popup.scss";
import Header from "../../../layouts/Header/Header";
import ToggleLang from "../../ToggleLang/ToggleLang";
import {LanguageContext} from "../../../contexts/LanguageContext";

class PopupMenu extends React.Component{

    /*constructor(props) {
        super(props);
        this.fields = this.props.fields;
        this.player = {};
        this.youtubeConfig = {
            width: "100%",
            height: "100%"
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }*/
    constructor(props) {
        super(props);
        this.isBusy = false;
        this.ref = React.createRef();
        this.btnOpen = props.btnOpen || null;
        this.state = {
            isOpen: false,
        }
    }

    componentDidMount() {
        this.popup = this.ref.current;
        this.overlay = this.popup.querySelector(".overlay");
        this.box = this.popup.querySelector(".box");
        this.btnClose = this.popup.querySelectorAll(".js-close");
        this.body = document.querySelector("body");

        if (this.btnOpen) {
            this.btnOpen = document.querySelectorAll(`.${this.btnOpen}`);
            this.btnOpen.forEach(btn => {
                btn.addEventListener("click", this.open.bind(this));
            });
        }

        this.overlay.addEventListener("click", this.close.bind(this));

        this.btnClose.forEach(btn => {
            btn.addEventListener("click", this.close.bind(this));
        });

    }

    componentWillUnmount() {
        this.overlay.removeEventListener("click", this.close.bind(this));
        this.btnClose.forEach(btn => {
            btn.removeEventListener("click", this.close.bind(this));
        });
        if (this.btnOpen) {
            this.btnOpen.forEach(btn => {
                btn.removeEventListener("click", this.open.bind(this));
            });
        }
    }

    close(afterClose, beforeClose) {

        if (this.isBusy) return false;
        this.isBusy = true;
        if (typeof beforeClose === "function") beforeClose(this);
        if (typeof this.beforeClose === "function") this.beforeClose(this);

        this.popup.classList.remove("open");
        if(this.btnOpen) {
            this.btnOpen.forEach(btn => {
                btn.classList.remove("active");
            });
        }

        setTimeout(() => {
            this.setState({
                isOpen: false,
            }, () => {
                this.popup.style.display = "none";
                this.body.classList.remove("fixed");
                this.changeFixed(false);
                if (typeof afterClose === "function") afterClose(this);
                if (typeof this.afterClose === "function") this.afterClose(this);
                this.isBusy = false;
            });
        }, 400);
    }

    open(event, afterOpen, beforeOpen) {

        if (this.isBusy) return false;
        this.isBusy = true;

        this.changeFixed(true);

        if (typeof beforeOpen === "function") beforeOpen(this);
        if (typeof this.beforeOpen === "function") this.beforeOpen(this, event.currentTarget);

        this.popup.style.display = "flex";

        if(this.btnOpen) {
            this.btnOpen.forEach(btn => {
                btn.classList.add("active");
            });
        }

        setTimeout(() => {
            this.popup.classList.add("open");
        }, 20);

        setTimeout(() => {
            this.setState({
                isOpen: true,
            }, () => {
                if (typeof afterOpen === "function") afterOpen(this);
                if (typeof this.afterOpen === "function") this.afterOpen(this);
                this.isBusy = false;
            });
        }, 500);
    }

    changeFixed(isFixed) {
        if (isFixed) {

            this.body.classList.add('fixed');
            // this.props.beforeOpen ? this.props.beforeOpen() : '';
            // this.scrollTop = window.pageYOffset;
            /*this.body.style.top = `-${this.scrollTop}px`;*/

        } else {
            this.body.classList.remove('fixed');

            // window.scrollTo(0, this.scrollTop);
            // this.body.style.top = '0px';
        }
    }

    render() {

        const { title, youtubeId, dir, menu } = this.props;


        return (
            <LanguageContext.Consumer>

                {context => {return (
            <div className={"pp-popup pp-popup-menu pp-popup-modal"} ref={this.ref}>
                <div className={"overlay"}></div>
                <div className={"close js-close"}>
                    <Svg name={"close"} />
                </div>
                <div className={"box"}>
                    <Header opened={true} dir={dir}/>
                    <div className={"menu-mobile"}>
                        {menu.map( item => <Link  activeClass="active"
                                                       to={item.link}
                                                       spy={true}
                                                       smooth={true}
                                                       duration={900}
                                                       offset={40}
                                                       className={`menu-item js-close`}>{context.getTranslate(item.text)}</Link>)}
                    </div>
                    <span className={"js-close close-text"}>{context.getTranslate("popupForm.close")}</span>
                </div>
            </div>
        ) }}
            </LanguageContext.Consumer>
        );
    }

}

export default PopupMenu;
