import React from 'react';
// import TagManager from 'react-gtm-module'
// import * as ReactGA from "react-ga";
import { regions } from "../../content/regions.json";
import Helmet from "react-helmet";

import Image from "../../components/UI/Images/Image";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";

import "./Regions.scss";
import Region from "../../components/Region/Region";

const Regions = () => {

  // const tagManagerArgs = {
  //   gtmId: 'GTM-KBHZMKJ'
  // };
  //
  // React.useEffect(() => {
  //   TagManager.initialize(tagManagerArgs);
  //   ReactGA.initialize('UA-206091290-1');
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <main>
    <Helmet>
      <meta name="facebook-domain-verification" content="ajsfl8ej8gxrzo5pcrav2e8ou4zu9x" />
    </Helmet>
      <section className={"pp-section-regions"}>
        <div className={"box"}>
          <Image className={"logo"}
                 src={"svg/logo.svg"}
                 alt={"Hyundai"} />
          <TitleBlock title={"Please select your region"} />
          <ul className={"regions"}>
            {regions.map(region => <Region key={region} region={region} />)}
          </ul>
        </div>
      </section>
    </main>
  );

};

Regions.propTypes = {};

export default Regions;
