import React, { useState } from 'react';
import cn from 'classnames';

import './accordeon.scss';

const Accordeon = ({
  renderMainElements,
  renderDropElements,
}) => {
  const [viewDropConten, setViewDropContent] = useState(false);

  return (
    <div className="accordeon">
      <div className="accordeon__main-elements">
        {renderMainElements()}
        <button
          className={
            cn('accordeon__button', { 'accordeon__button--active': viewDropConten })
          }
          onClick={() => setViewDropContent(!viewDropConten)}
        />
      </div>
      {viewDropConten && (
        <div className="accordeon__dropdown">
          {renderDropElements()}
        </div>
      )}
    </div>
  );
};

export default Accordeon;
