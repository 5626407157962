import React from 'react';
import PropTypes from 'prop-types';
import "./Button.scss";
import { LanguageContext } from "../../../contexts/LanguageContext";
import Svg from "../SvgSprite/Svg";

const Button = ({ children, text, onClick, className, link, svgName, isFile }) => {

  const filePath = process.env.PUBLIC_URL + "/assets/files/";

  if (link) {
    return (
        <LanguageContext.Consumer>
          {context => {

            return <a href={isFile ? filePath + context.getTranslate(link) : context.getTranslate(link)}
                      target={"_blank"}
                      download={isFile}
                      rel={"noreferrer noopener"}
                      className={`btn ${className}`}
                      onClick={onClick}>{context.getTranslate(text)}</a>

          }}
        </LanguageContext.Consumer>
    )
  }
  else if (svgName) {

    return (
        <LanguageContext.Consumer>
          {context => {

            return (
                <button className={`btn-icon ${className} ${context.dir}`}>
                  <div className={"icon"}>
                    <Svg name={svgName} />
                  </div>
                  {children}
                  <span>{context.getTranslate(text)}</span>
                </button>
            )

          }}
        </LanguageContext.Consumer>
    )
  }
  else {
    return (
        <LanguageContext.Consumer>
          {context => {
            return <button className={`btn ${className}`} onClick={onClick}>{context.getTranslate(text)}</button>
          }}
        </LanguageContext.Consumer>
    )
  }

};

Button.defaultProps = {
  className: "",
  onClick: () => {},
  text: "",
  svgName: null,
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  svgName: PropTypes.string,
};

export default Button;
