import React, { useContext } from 'react';
import cn from 'classnames';

import Accordeon from '../../components/Accordeon/Accordeon';
import Image from '../../components/UI/Images/Image';
import TitleBlock from '../../components/UI/TitleBlock/TitleBlock';
import { LanguageContext } from '../../contexts/LanguageContext';

import './carView.scss';
import Good from '../Good/Good';
import { TileContainer } from '../tileContainer/TileContainer';

const CarView = ({
  carViewConf,
  className,
  id,
}) => {
  const { getTranslate, lang } = useContext(LanguageContext);
  const dir = getTranslate('direction');

  const mainElements = () => (
    <div className="car-view__main-content">
      <TitleBlock
        title={carViewConf.title}
        subtitle={carViewConf.description}
      />
      <Image src={carViewConf.img} />
    </div>
  );

  const dropElements = () => (
    <div className="car-view__dropdown-content">
      <Good
        changeTitle
        className={"centered transparent car-view__good"}
        slideType={"PictureSlide"}
        sliderClassName={"slim"}
        slideClassName={"white-counter"}
        slides={carViewConf.slides}
        dir={dir}
      />
      <TileContainer
        textAbove={"features.features"}
        smallSlides={carViewConf.features}
        dir={dir}
        
      />
    </div>
  );

  return (
    <section
      className={cn("pp-section", "car-view", className)}
      id={id}
    >
      <div className="container">
        <Accordeon
          renderMainElements={mainElements}
          renderDropElements={dropElements}
        />
      </div>
    </section>
  )
}

export default CarView;
