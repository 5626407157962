import React from 'react';

import './tabs.scss';

const Tabs = ({
  tabItems,
  onClickItem,
  lang,
  hideElement,
}) => {
  const handleClick = (itemName) => {
    onClickItem(itemName);
  }

  return (
    <ul className="tabs">
      {tabItems.map((item) => {
        //TODO: добавить проверку по масиву
        if (hideElement === item.id) return null;

        return (
          <li key={item.id} className="tabs__item">
            <button
              onClick={() => handleClick(item)}
              className="tabs__button"
            >
              {item.name[lang]}
            </button>
          </li>
        )
      })}
    </ul>
  );
};

export default Tabs;

Tabs.defaultProps = {
  tabItems: []
}
