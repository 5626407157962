import React from 'react';
import Image from "../../components/UI/Images/Image";
import "./thankyou.scss";
import { LanguageContext } from "../../contexts/LanguageContext";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

class ThankYou extends React.Component {

  constructor(props) {
    super(props);
    this.tagManagerArgs = {
      gtmId: 'GTM-WSXB57X'
    };
    this.state = {
      time: 5
    }

    this.region = this.props.match.params.region;
    this.lang = LanguageContext._currentValue.lang;
    this.location = window.location.href.split("?")[0];
    this.redirectPages = {
      "riyadh": {
        "en": "https://www.hyundai.com/wallan/en/find-a-car/staria-2021/highlights",
        "ar": "https://www.hyundai.com/wallan/ar/find-a-car/staria-2021/highlights",
      },
      "dammam": {
        "en": "https://www.hyundai.com/almajdouie/en/find-a-car/staria-2021/highlights",
        "ar": "https://www.hyundai.com/almajdouie/ar/find-a-car/staria-2021/highlights",
      },
      "jeddah": {
        "en": "https://www.hyundai.com/mynaghi/en/find-a-car/hyundai-staria/highlights",
        "ar": "https://www.hyundai.com/mynaghi/ar/find-a-car/hyundai-staria/highlights",
      }
    }
  }

  componentDidMount() {
    TagManager.initialize(this.tagManagerArgs);
    ReactGA.initialize('UA-192304168-1',{ debug: true,});
    ReactGA.pageview(this.region);
    this.timer();
    const noscript = document.createElement("noscript");
    const country = ["Jeddah", "Dammam", "Riyadh"].includes(this.region) ? "Saudi Arabia" : this.region;
    noscript.innerHTML = `<img src="https://ad.doubleclick.net/ddm/activity/src=3220295;type=gquothn;cat=hyund0;u1=${this.location};u2=${this.lang};u3=${country};u4=${this.region};u5=Tucson;u6=${''};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?" width="1" height="1" alt=""/>`;

    window.gtag('event', 'conversion', {
      'allow_custom_scripts': true,
      'u1': this.location,
      'u2': this.lang,
      'u3': country,
      'u4': this.region,
      'u5': 'Tucson',
      'u6': '',
      'send_to': 'DC-3220295/gquothn/hyund0+unique'
    });
    document.querySelector("body").appendChild(noscript);
  }

  timer() {
    const tick = setInterval(() => {
      if (this.state.time > 0) {
        this.setState({ time: this.state.time - 1 })
      }
      if (this.state.time <= 0) {
        clearInterval(tick);
        let region = this.region.toString().toLowerCase();
        if (this.redirectPages[region]) {
          window.location = this.redirectPages[region][this.lang];
        } else {
          this.props.history.push(`${process.env.REACT_APP_URL}${this.region}${this.lang === 'ar' ? '' : '/' + this.lang}`);
        }
      }
    }, 1000);
  }

  render() {
    return (
      <LanguageContext.Consumer>
        {context => {
          return (
            <main>
              <section className={"pp-section-thankyou"}>
                <div>
                  <Image src={"svg/logo.svg"}
                         alt={"Hyundai"}
                         className={"logo"} />
                  <h1>
                    {context.getTranslate("thanks.title")}
                  </h1>
                  <h2 className={"subtitle"}>
                    {context.getTranslate("thanks.subtitle")}
                  </h2>
                  <span className={"timer"}>{this.state.time}</span>
                </div>
              </section>
            </main>
          )
        }}
      </LanguageContext.Consumer>
    )
  }
}

export default ThankYou;
