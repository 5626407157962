import React from 'react';
import PropTypes from "prop-types";
import Image from "../../components/UI/Images/Image";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import "./Hero.scss";
import Videos from '../../components/Videos/Videos';

const Hero = ({region, dir, useYoutube, video}) => {

        const videoRef = React.createRef();

        React.useEffect(() => {
            if (videoRef.current) {
                videoRef.current.controls = false;
                videoRef.current.defaultMuted = true;
                videoRef.current.setAttribute("muted", "muted");
            }
        });

        const images = [
            {
                "src": `desktop/hero/${region.toLowerCase()}-2x.jpg`,
                "media": "1920"
            },
            {
                "src": `mobile/hero/${region.toLowerCase()}-2x.jpg`,
                "media": "768px"
            },
        ];

        return (
            <section className={`pp-section-hero ${region}`} dir={dir}>
                {video ?
                    <div className={"wrapper_video"}>
                        <video ref={videoRef} className={"video js-hero-video"} playsInline muted="muted" loop pip="false"
                               autoPlay>
                            <source type="video/mp4" src={video}/>
                        </video>
                    </div>
                    :
                    <Image className={"banner"} src={images} useLazy={false}/>
                }
                <div className={"container"}>
                    <TitleBlock
                        isMain={true}
                        className={"white"}
                        title={"hero.title"}
                        subtitle={"hero.subtitle"}
                        useYoutube={useYoutube}
                    />
                </div>
            </section>
        );

    }
;

Hero.propTypes = {
    region: PropTypes.string.isRequired,
    dir: PropTypes.string,
};

export default Hero;
