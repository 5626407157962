import React from "react";
import YouTube from 'react-youtube';
import Popup from "./Popup";
import Svg from "../UI/SvgSprite/Svg";
import cn from 'classnames';

import "./popup.scss";
import {LanguageContext} from "../../contexts/LanguageContext";
import Videos from '../Videos/Videos';
import Button from '../UI/Button/Button';

class PopupYoutube extends Popup {

  constructor(props) {
    super(props);
    this.fields = this.props.fields;
    this.player = {};
    this.youtubeConfig = {
      width: "100%",
      height: "100%",
      playerVars: {
        loop: 1,
        controls: 1,
        iv_load_policy: 3,
        rel: 0
      }
    };
    this.state = {
      currentVideo: 0,
      browserZoomLevel: 0,
    };
  }

  changeRatio = () => {
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    this.setState({ browserZoomLevel })
  }

  componentDidMount() {
    if (this.state.browserZoomLevel === 0) {
      this.changeRatio();
    }
    super.componentDidMount();
    window.addEventListener('resize', this.changeRatio)
  }

  componentWillUnmount() {
    this.setState({ currentVideo: 0 });
    window.removeEventListener('resize', this.changeRatio)
  }

  beforeOpen() {
    try {
      this.player.playVideo();
    } catch (e) {}
  }

  beforeClose() {
    try {
      this.player.stopVideo();
      this.setState({
        currentVideo: 0,
      })
    } catch (e) {}
  }

  _onReady(event) {
    this.player = event.target;
    event.target.pauseVideo();
  }

  _onEnd(event) {
    event.target.playVideo();
  }

  handleChangeVideo = ({ key }) => {
    this.setState({ currentVideo: key })
  }

  nextVideo = () => {
    this.setState({ currentVideo: this.state.currentVideo + 1 })
  }

  prevVideo = () => {
    this.setState({ currentVideo: this.state.currentVideo - 1 })
  }

  render() {
    const { moreVideos, youtubeId } = this.props;
    const { currentVideo, browserZoomLevel } = this.state;

    const videoId = moreVideos ? moreVideos[currentVideo].videoId : youtubeId;

    return (
        <LanguageContext.Consumer>
          {context => {
          const dir = context.getTranslate('direction');  
          return (
            <div className={"pp-popup pp-popup-youtube pp-popup-modal"} ref={this.ref}>
              <div className={"overlay"}></div>
              <div className={"close js-close"}>
                <Svg name={"close"} />
              </div>
              {moreVideos && (
                <>
                  <button
                    className={cn(
                      'control control--prev',
                    )} 
                    onClick={this.prevVideo}
                    disabled={currentVideo === 0}
                    dir={dir}
                    pixel-ratio={browserZoomLevel}
                  >
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 15L8 8L1 0.999999" fill="none" />
                    </svg>
                  </button>
                  <button
                    className="control control--next"
                    onClick={this.nextVideo}
                    disabled={currentVideo === moreVideos.length - 1}
                    dir={dir}
                    pixel-ratio={browserZoomLevel}
                  >
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 15L8 8L1 0.999999" fill="none" />
                    </svg>
                  </button>
                </>
              )}
              <div className={"box box--change-pos"} dir={dir}>
                {moreVideos && (
                  <Videos
                    videoConf={moreVideos}
                    className={cn('videos__popup-youtub', 'videos--list')}
                    onChangeVideo={this.handleChangeVideo}
                    currentVideo={currentVideo}
                  />
                )}
                <YouTube
                  className="video-iframe"
                  playerVars={{ "part": "snippet" }}
                  videoId={videoId}
                  opts={this.youtubeConfig}
                  onReady={this._onReady.bind(this)}
                  onEnd={this._onEnd.bind(this)}
                  containerClassName={"youtube"} />
              </div>
            </div>
          )}}
        </LanguageContext.Consumer>
    );
  }

}

export default PopupYoutube;
