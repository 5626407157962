import React from 'react';
import SvgSprite from 'react-svg-sprite'

const Sprite = () => {
  return (
    <SvgSprite
      style={{
        position: "absolute",
        top: "-99999px",
        left: "-99999px",
        zIndex: "19999999",
        display: "block"
      }}
      symbols={[
        {
          name: "arrow",
          svg: `
					<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 15L8 8L1 0.999999" fill="none" />
					</svg>`
        },
        {
          name: "arrow-reverse",
          svg: `
					<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8 15L0.999999 8L8 0.999999" fill="none"/>
					</svg>
					`
        },
        {
          name: "arrow-color-reverse",
          svg: `
						<svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M11 1L1 11L11 21" fill="none" />
						</svg>
					`
        },
        {
          name: "arrow-color",
          svg: `
						<svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L11 11L1 21" fill="none" />
						</svg>
					`
        },
        {
          name: "play",
          svg: `
						<svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.41431 1.69437L14.0625 10.8643L1.41431 20.0342L1.41431 1.69437Z" stroke="white"/>
						</svg>
					`
        },
        {
          name: "close",
          svg: `
					<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
					    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
					        <g id="Artboard-Copy-27" transform="translate(-362.000000, -777.000000)" stroke="#FFFFFF" stroke-width="2">
					            <g id="close" transform="translate(363.000000, 778.000000)">
					                <path d="M0,0 L18,18" id="Path-6"></path>
					                <path d="M0,18 L18,0" id="Path-7"></path>
					            </g>
					        </g>
					    </g>
					</svg>
					`
        },
        {
          name: "bsvm",
          svg: `
					<svg width="48" height="76" viewBox="0 0 48 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path  d="M3.01938 7.5C3.01938 3.63401 6.15339 0.5 10.0194 0.5H12.0194C15.8854 0.5 19.0194 3.63401 19.0194 7.5V10.8193C19.0618 10.85 19.1024 10.8846 19.1407 10.9228L21.98 13.7621C22.3705 14.1527 22.3705 14.7858 21.98 15.1763C21.5895 15.5669 20.9563 15.5669 20.5658 15.1763L19.0194 13.6299V33.5C19.0194 36.2614 16.7808 38.5 14.0194 38.5H8.01938C5.25796 38.5 3.01938 36.2614 3.01938 33.5V13.8641L1.70711 15.1763C1.31658 15.5669 0.683417 15.5669 0.292893 15.1763C-0.0976311 14.7858 -0.0976311 14.1527 0.292893 13.7621L3.01938 11.0356V7.5ZM17.0199 13C17.0199 9.50003 14.6731 8.5 11.0194 8.5C7.36577 8.5 5.01949 9.5 5.01949 13C5.01949 13.2452 5.01704 13.4781 5.0147 13.6993V13.6993V13.6993L5.0147 13.6993C4.98376 16.6353 4.97465 17.5 11.0194 17.5C17.0646 17.5 17.0555 16.6351 17.0246 13.6986L17.0246 13.6984V13.6982V13.698C17.0223 13.4772 17.0199 13.2447 17.0199 13ZM11.0191 34.5C14.6673 34.5 17.0105 33.9444 17.0105 32C17.0105 31.864 17.013 31.7348 17.0153 31.6122V31.612L17.0153 31.6119C17.0461 29.9805 17.0552 29.5 11.0191 29.5C4.98349 29.5 4.99259 29.9804 5.02349 31.6115C5.02582 31.7344 5.02827 31.8638 5.02827 32C5.02827 33.9444 7.371 34.5 11.0191 34.5ZM16.0194 18.9142C16.0194 18.649 16.1247 18.3946 16.3123 18.2071L18.0194 16.5V29.5L16.5722 28.7764C16.2334 28.607 16.0194 28.2607 16.0194 27.882V18.9142ZM5.72649 18.2071C5.91402 18.3946 6.01938 18.649 6.01938 18.9142V27.882C6.01938 28.2607 5.80538 28.607 5.46659 28.7764L4.01938 29.5V16.5L5.72649 18.2071Z" />
                          <path d="M21.8565 37.4281H20.7562C20.7562 38.9865 19.487 40.2558 17.9285 40.2558L17.9278 41.3568C20.0944 41.3554 21.8558 39.594 21.8565 37.4281Z" />
                          <path  d="M25.2344 37.4284L24.1341 37.4284C24.1341 39.0858 23.4892 40.6443 22.3168 41.8167C21.1452 42.9883 19.5867 43.6332 17.9278 43.6332L17.9285 44.7342C19.8794 44.7335 21.7151 43.974 23.0946 42.5945C24.4749 41.2142 25.2344 39.38 25.2344 37.4284Z" />
                          <path  d="M28.6122 37.4278H27.5119C27.5119 39.9875 26.5149 42.3945 24.7047 44.2047C22.8945 46.0149 20.4882 47.0112 17.9271 47.0126L17.9278 48.1121C20.7824 48.1128 23.4659 47.0006 25.4832 44.9832C27.5013 42.9651 28.6136 40.2816 28.6122 37.4278Z" />
                          <path  d="M28.0387 75.2507V61.8641L26.7264 63.1763C26.3359 63.5669 25.7027 63.5669 25.3122 63.1763C24.9217 62.7858 24.9217 62.1527 25.3122 61.7621L28.0387 59.0356V55.5C28.0387 51.634 31.1727 48.5 35.0387 48.5H37.0387C40.9047 48.5 44.0387 51.634 44.0387 55.5V58.8193C44.0811 58.85 44.1217 58.8846 44.16 58.9228L46.9993 61.7621C47.3898 62.1527 47.3898 62.7858 46.9993 63.1763C46.6088 63.5669 45.9756 63.5669 45.5851 63.1763L44.0387 61.6299V69.9246C43.7083 70.1191 43.3749 70.3091 43.0387 70.4944V64.5L41.3316 66.2071C41.144 66.3946 41.0387 66.649 41.0387 66.9142V71.525C37.9076 73.0296 34.5532 74.1434 31.0387 74.8033V66.9142C31.0387 66.649 30.9333 66.3946 30.7458 66.2071L29.0387 64.5V75.1271C28.7066 75.1723 28.3732 75.2135 28.0387 75.2507ZM36.0387 56.5C39.6924 56.5 42.0392 57.5 42.0392 61C42.0392 61.2447 42.0416 61.4771 42.0439 61.6978V61.698V61.6982V61.6984L42.0439 61.6986C42.0748 64.6351 42.0839 65.5 36.0387 65.5C29.9939 65.5 30.003 64.6353 30.034 61.6993L30.034 61.6993V61.6993V61.6993V61.6992C30.0363 61.4781 30.0388 61.2452 30.0388 61C30.0388 57.5 32.3851 56.5 36.0387 56.5Z" />
                        </svg>
					`
        },
        {
          name: "rspa",
          svg: `
				<svg width="82" height="79" viewBox="0 0 82 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path  d="M42.9998 6.44406L42.9998 16.5001L38.934 16.5001L38.934 6.44406L36.9998 6.44406L40.9998 -1.74846e-07L44.9998 6.44406L42.9998 6.44406Z" />
                          <path  d="M33.0191 28.5C33.0191 24.634 36.1531 21.5 40.0191 21.5L42.0191 21.5C45.8851 21.5 49.0191 24.634 49.0191 28.5L49.0191 31.8193C49.0616 31.8501 49.1022 31.8846 49.1404 31.9228L51.9797 34.7621C52.3703 35.1527 52.3703 35.7858 51.9797 36.1763C51.5892 36.5669 50.9561 36.5669 50.5655 36.1763L49.0191 34.6299L49.0191 54.5C49.0191 57.2614 46.7806 59.5 44.0191 59.5L38.0191 59.5C35.2577 59.5 33.0191 57.2614 33.0191 54.5L33.0191 34.8641L31.7069 36.1763C31.3163 36.5669 30.6832 36.5669 30.2926 36.1763C29.9021 35.7858 29.9021 35.1527 30.2926 34.7621L33.0191 32.0356L33.0191 28.5ZM47.0196 34C47.0196 30.5 44.6728 29.5 41.0192 29.5C37.3655 29.5 35.0192 30.5 35.0192 34C35.0192 34.2452 35.0168 34.4781 35.0145 34.6993L35.0145 34.6993L35.0145 34.6993L35.0145 34.6993C34.9835 37.6353 34.9744 38.5 41.0192 38.5C47.0644 38.5 47.0553 37.6351 47.0244 34.6986L47.0244 34.6984L47.0244 34.6982L47.0244 34.698C47.0221 34.4772 47.0196 34.2447 47.0196 34ZM41.0189 55.5C44.667 55.5 47.0103 54.9444 47.0103 53C47.0103 52.864 47.0127 52.7348 47.015 52.6122L47.015 52.612L47.015 52.6119C47.0459 50.9805 47.0549 50.5 41.0189 50.5C34.9832 50.5 34.9923 50.9804 35.0232 52.6115L35.0232 52.6115C35.0256 52.7344 35.028 52.8638 35.028 53C35.028 54.9444 37.3707 55.5 41.0189 55.5ZM46.0191 39.9142C46.0191 39.649 46.1245 39.3946 46.312 39.2071L48.0191 37.5L48.0191 50.5L46.5719 49.7764C46.2331 49.607 46.0191 49.2607 46.0191 48.882L46.0191 39.9142ZM35.7262 39.2071C35.9138 39.3946 36.0191 39.649 36.0191 39.9142L36.0191 48.882C36.0191 49.2607 35.8051 49.607 35.4663 49.7764L34.0191 50.5L34.0191 37.5L35.7262 39.2071Z" />
                          <path  d="M3.01913 44.5C3.01914 40.634 6.15314 37.5 10.0191 37.5L12.0191 37.5C15.8851 37.5 19.0191 40.634 19.0191 44.5L19.0191 47.8193C19.0616 47.8501 19.1022 47.8846 19.1404 47.9228L21.9797 50.7621C22.3703 51.1527 22.3703 51.7858 21.9797 52.1763C21.5892 52.5669 20.9561 52.5669 20.5655 52.1763L19.0191 50.6299L19.0191 70.5C19.0191 73.2614 16.7806 75.5 14.0191 75.5L8.01913 75.5C5.25771 75.5 3.01913 73.2614 3.01913 70.5L3.01913 50.8641L1.70686 52.1763C1.31634 52.5669 0.683171 52.5669 0.292646 52.1763C-0.0978777 51.7858 -0.0978776 51.1527 0.292647 50.7621L3.01913 48.0356L3.01913 44.5ZM17.0196 50C17.0196 46.5 14.6728 45.5 11.0192 45.5C7.36552 45.5 5.01924 46.5 5.01924 50C5.01924 50.2452 5.01679 50.4781 5.01446 50.6993L5.01446 50.6993L5.01446 50.6993L5.01446 50.6993C4.98351 53.6353 4.9744 54.5 11.0192 54.5C17.0644 54.5 17.0553 53.6351 17.0244 50.6986L17.0244 50.6984L17.0244 50.6982L17.0244 50.698C17.0221 50.4772 17.0196 50.2447 17.0196 50ZM11.0189 71.5C14.667 71.5 17.0103 70.9444 17.0103 69C17.0103 68.864 17.0127 68.7348 17.015 68.6122L17.015 68.612L17.015 68.6119C17.0459 66.9805 17.0549 66.5 11.0189 66.5C4.98324 66.5 4.99234 66.9804 5.02324 68.6115L5.02324 68.6115C5.02557 68.7344 5.02802 68.8638 5.02802 69C5.02802 70.9444 7.37075 71.5 11.0189 71.5ZM16.0191 55.9142C16.0191 55.649 16.1245 55.3946 16.312 55.2071L18.0191 53.5L18.0191 66.5L16.5719 65.7764C16.2331 65.607 16.0191 65.2607 16.0191 64.882L16.0191 55.9142ZM5.72624 55.2071C5.91378 55.3946 6.01913 55.649 6.01913 55.9142L6.01913 64.882C6.01913 65.2607 5.80513 65.607 5.46634 65.7764L4.01913 66.5L4.01913 53.5L5.72624 55.2071Z" />
                          <path  d="M62.7464 44.5C62.7464 40.634 65.8804 37.5 69.7464 37.5L71.7464 37.5C75.6124 37.5 78.7464 40.634 78.7464 44.5L78.7464 47.8193C78.7889 47.8501 78.8295 47.8846 78.8677 47.9228L81.707 50.7621C82.0976 51.1527 82.0976 51.7858 81.707 52.1763C81.3165 52.5669 80.6834 52.5669 80.2928 52.1763L78.7464 50.6299L78.7464 70.5C78.7464 73.2614 76.5078 75.5 73.7464 75.5L67.7464 75.5C64.985 75.5 62.7464 73.2614 62.7464 70.5L62.7464 50.8641L61.4342 52.1763C61.0436 52.5669 60.4105 52.5669 60.0199 52.1763C59.6294 51.7858 59.6294 51.1527 60.0199 50.7621L62.7464 48.0356L62.7464 44.5ZM76.7469 50C76.7469 46.5 74.4001 45.5 70.7465 45.5C67.0928 45.5 64.7465 46.5 64.7465 50C64.7465 50.2452 64.7441 50.4781 64.7418 50.6993L64.7418 50.6993L64.7418 50.6993L64.7418 50.6993C64.7108 53.6353 64.7017 54.5 70.7465 54.5C76.7917 54.5 76.7826 53.6351 76.7517 50.6986L76.7517 50.6984L76.7517 50.6982L76.7517 50.698C76.7494 50.4772 76.7469 50.2447 76.7469 50ZM70.7462 71.5C74.3943 71.5 76.7376 70.9444 76.7376 69C76.7376 68.864 76.74 68.7348 76.7423 68.6122L76.7423 68.612L76.7423 68.6119C76.7732 66.9805 76.7822 66.5 70.7462 66.5C64.7105 66.5 64.7196 66.9804 64.7505 68.6115L64.7505 68.6115C64.7529 68.7344 64.7553 68.8638 64.7553 69C64.7553 70.9444 67.098 71.5 70.7462 71.5ZM75.7464 55.9142C75.7464 55.649 75.8518 55.3946 76.0393 55.2071L77.7464 53.5L77.7464 66.5L76.2992 65.7764C75.9604 65.607 75.7464 65.2607 75.7464 64.882L75.7464 55.9142ZM65.4535 55.2071C65.6411 55.3946 65.7464 55.649 65.7464 55.9142L65.7464 64.882C65.7464 65.2607 65.5324 65.607 65.1936 65.7764L63.7464 66.5L63.7464 53.5L65.4535 55.2071Z" />
                          <path  d="M51.7778 16.1674L50.9998 16.9454C52.1018 18.0474 52.1018 19.8424 50.9998 20.9444L51.7778 21.7234C53.3088 20.1904 53.3088 17.6994 51.7778 16.1674Z" />
                          <path d="M54.1665 13.7791L53.3884 14.5571C54.5604 15.7291 55.2065 17.2871 55.2065 18.9451C55.2065 20.6021 54.5605 22.1601 53.3875 23.3331L54.1665 24.1111C55.5455 22.7311 56.3065 20.8961 56.3065 18.9451C56.3065 16.9931 55.5465 15.1591 54.1665 13.7791Z" />
                          <path d="M56.5546 11.39L55.7766 12.168C57.5866 13.978 58.5836 16.385 58.5836 18.945C58.5836 21.505 57.5866 23.911 55.7766 25.723L56.5546 26.5C58.5736 24.482 59.6846 21.798 59.6846 18.945C59.6846 16.091 58.5736 13.407 56.5546 11.39Z" />
                          <path d="M56.9998 78.5L54.9998 78.5L54.9998 34.5L56.9998 34.5L56.9998 78.5Z" />
                          <path d="M52 78.5L52 76.5L60 76.5L60 78.5L52 78.5Z" />
                          <path d="M22 78.5L22 76.5L30 76.5L30 78.5L22 78.5Z"/>
                          <path d="M26.9998 78.5L24.9998 78.5L24.9998 34.5L26.9998 34.5L26.9998 78.5Z" />
                        </svg>
					`
        },
        {
          name: "sbw",
          svg: `

<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="78pt" height="84pt" viewBox="0 0 78 84" version="1.1">
<g id="surface1">
<path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 69.6875 6.839844 L 77.925781 73.164062 C 78.640625 78.925781 74.136719 83.765625 67.839844 83.800781 L 40.308594 83.9375 C 33.945312 83.96875 28.894531 79.136719 29.035156 73.332031 L 29.429688 56.664062 C 29.550781 51.601562 25.023438 47.617188 19.316406 47.617188 L 9.375 47.617188 C 3.644531 47.617188 -0.527344 43.71875 0.0625 39.042969 L 4.125 6.441406 C 4.5625 2.84375 8.84375 0.03125 13.679688 0.0625 L 60.273438 0.390625 C 65.035156 0.433594 69.246094 3.277344 69.6875 6.839844 Z M 69.6875 6.839844 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 59.972656 22.167969 L 41.113281 22.09375 C 39.800781 22.09375 38.71875 21.25 38.710938 20.25 L 38.621094 6.386719 C 38.621094 5.476562 39.621094 4.742188 40.855469 4.75 L 58.546875 4.863281 C 59.773438 4.863281 60.84375 5.605469 60.925781 6.527344 L 62.210938 20.328125 C 62.285156 21.34375 61.289062 22.171875 59.972656 22.167969 Z M 59.972656 22.167969 "/>
<path style="fill:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:10;" d="M 159.003434 58.355091 L 112.213661 58.175301 C 108.700758 58.175301 105.796059 55.890912 105.775087 53.183489 L 105.460498 18.452319 C 105.460498 15.966989 108.134499 13.978725 111.458649 13.999877 L 155.52199 14.274849 C 158.814681 14.274849 161.677435 16.326569 161.897647 18.801323 L 164.896722 53.43731 C 165.200824 56.144733 162.53731 58.376243 159.003434 58.355091 Z M 159.003434 58.355091 " transform="matrix(0.372511,0,0,0.369355,0,0.063453)"/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 25.867188 39.882812 L 11.789062 39.863281 C 10.382812 39.863281 9.316406 38.910156 9.425781 37.753906 L 11.921875 11.203125 C 12.007812 10.253906 13.117188 9.488281 14.378906 9.496094 L 27.019531 9.570312 C 28.277344 9.570312 29.28125 10.355469 29.253906 11.300781 L 28.507812 37.785156 C 28.445312 38.9375 27.273438 39.882812 25.867188 39.882812 Z M 25.867188 39.882812 "/>
<path style="fill:none;stroke-width:1.03;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:10;" d="M 66.99683 105.396577 L 34.426451 105.354274 C 30.661877 105.354274 27.809609 102.784336 28.092739 99.67503 L 34.09089 32.222107 C 34.321588 29.641594 37.299691 27.558147 40.697245 27.579298 L 70.100769 27.737936 C 73.487837 27.737936 76.193296 29.874263 76.09892 32.454776 L 74.001664 99.759637 C 73.875829 102.858368 70.729945 105.407153 66.99683 105.396577 Z M 66.99683 105.396577 " transform="matrix(0.372511,0,0,0.369355,0,0.063453)"/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 62.007812 45.832031 L 41.550781 45.832031 C 40.125 45.832031 38.941406 44.847656 38.941406 43.652344 L 38.835938 27.394531 C 38.835938 26.335938 39.910156 25.476562 41.242188 25.480469 L 60.347656 25.550781 C 61.667969 25.550781 62.828125 26.417969 62.925781 27.476562 L 64.414062 43.652344 C 64.492188 44.851562 63.421875 45.832031 62.007812 45.832031 Z M 62.007812 45.832031 "/>
<path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 61.128906 45.125 L 42.367188 45.125 C 40.945312 45.125 39.757812 44.140625 39.757812 42.953125 L 39.617188 28.039062 C 39.617188 26.976562 40.6875 26.109375 42.019531 26.113281 L 59.605469 26.171875 C 60.929688 26.171875 62.089844 27.042969 62.183594 28.109375 L 63.5 42.976562 C 63.609375 44.15625 62.539062 45.125 61.128906 45.125 Z M 61.128906 45.125 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 64.257812 73.882812 L 41.90625 73.960938 C 40.40625 74.015625 39.136719 72.863281 39.058594 71.375 L 38.933594 52.109375 C 38.933594 50.855469 40.101562 49.835938 41.542969 49.835938 L 62.273438 49.835938 C 63.707031 49.835938 64.972656 50.851562 65.089844 52.097656 L 66.835938 71.304688 C 66.957031 72.699219 65.804688 73.878906 64.257812 73.882812 Z M 64.257812 73.882812 "/>
<path style="fill:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:10;" d="M 170.108402 197.78741 L 114.677937 197.988352 C 110.514884 197.988352 107.064899 194.836741 107.043926 190.987123 L 106.613989 142.697684 C 106.613989 139.292253 109.728413 136.531949 113.608336 136.531949 L 165.253256 136.531949 C 169.112206 136.531949 172.50976 139.281677 172.803376 142.676532 L 177.029346 190.775606 C 177.364907 194.614648 174.260969 197.776834 170.108402 197.78741 Z M 170.108402 197.78741 " transform="matrix(0.372511,0,0,0.369355,0,0.063453)"/>
<path style="fill:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:10;" d="M 177.857762 212.202326 L 108.700758 212.487875 C 100.175414 212.487875 93.306902 206.057743 93.359333 198.168142 L 94.544283 15.628561 C 94.544283 10.71078 99.955202 6.808282 106.540585 6.850586 L 160.094007 7.220741 C 166.637444 7.220741 172.352466 11.218422 172.855807 16.125627 L 191.66819 197.829714 C 192.475634 205.666436 186.309702 212.160022 177.857762 212.202326 Z M 177.857762 212.202326 " transform="matrix(0.372511,0,0,0.369355,0,0.063453)"/>
<path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 20.558594 21.53125 L 18.128906 21.53125 L 17.800781 26.402344 L 19 26.402344 L 19.257812 22.339844 L 20.375 22.339844 C 21.078125 22.339844 21.492188 22.527344 21.476562 23.046875 C 21.457031 23.570312 21 23.75 20.289062 23.75 L 19.917969 23.75 L 19.871094 24.570312 L 20.386719 24.570312 C 21.707031 24.570312 22.621094 24.085938 22.691406 23.050781 C 22.765625 22.019531 21.863281 21.539062 20.558594 21.53125 Z M 20.558594 21.53125 "/>
<path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 50.695312 13.480469 C 51.304688 13.355469 51.691406 13 51.667969 12.480469 C 51.628906 11.820312 50.964844 11.421875 50.066406 11.417969 L 47.992188 11.417969 L 48.140625 14.976562 L 49.042969 14.976562 L 48.910156 12.023438 L 50.003906 12.023438 C 50.472656 12.023438 50.746094 12.191406 50.746094 12.488281 C 50.746094 12.785156 50.515625 12.949219 50.046875 12.945312 L 49.695312 12.945312 L 49.722656 13.542969 L 51.210938 15.019531 L 52.296875 15.019531 Z M 50.695312 13.480469 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 48.96875 33.019531 L 49.871094 33.019531 L 52.960938 36.042969 L 52.796875 33.027344 L 53.820312 33.027344 L 54.082031 37.460938 L 53.171875 37.460938 L 50.0625 34.414062 L 50.195312 37.457031 L 49.171875 37.457031 Z M 48.96875 33.019531 "/>
<path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 52.777344 59.6875 L 50.40625 59.6875 L 50.632812 64.988281 L 51.777344 64.988281 L 51.738281 64.09375 L 51.738281 63.886719 L 51.585938 60.5625 L 52.78125 60.5625 C 53.898438 60.5625 54.644531 61.167969 54.703125 62.316406 C 54.761719 63.46875 54.085938 64.097656 52.957031 64.101562 L 52.636719 64.101562 L 52.679688 64.992188 L 53.054688 64.992188 C 54.773438 64.992188 55.960938 64.039062 55.859375 62.316406 C 55.753906 60.597656 54.46875 59.683594 52.777344 59.6875 Z M 52.777344 59.6875 "/>
</g>
</svg>


					`
        },
        {
          name: "svm",
          svg: `
			 <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M26.1947 22.675C26.1947 18.8091 29.3287 15.675 33.1947 15.6751L35.1947 15.6751C39.0607 15.6751 42.1947 18.8091 42.1947 22.6751L42.1947 25.9943C42.2371 26.0251 42.2777 26.0596 42.316 26.0979L45.1553 28.9372C45.5458 29.3277 45.5458 29.9609 45.1553 30.3514C44.7648 30.7419 44.1316 30.7419 43.7411 30.3514L42.1947 28.805L42.1947 48.6751C42.1947 51.4365 39.9561 53.6751 37.1947 53.6751L31.1947 53.6751C28.4332 53.675 26.1947 51.4365 26.1947 48.675L26.1947 29.0391L24.8824 30.3514C24.4919 30.7419 23.8587 30.7419 23.4682 30.3514C23.0777 29.9609 23.0777 29.3277 23.4682 28.9372L26.1947 26.2107L26.1947 22.675ZM40.1952 28.1751C40.1952 24.6751 37.8484 23.6751 34.1947 23.6751C30.5411 23.6751 28.1948 24.675 28.1948 28.1751C28.1948 28.4203 28.1923 28.6532 28.19 28.8743L28.19 28.8743L28.19 28.8744L28.19 28.8744C28.1591 31.8103 28.1499 32.6751 34.1947 32.6751C40.2399 32.6751 40.2308 31.8101 40.1999 28.8737L40.1999 28.8735L40.1999 28.8733L40.1999 28.8731C40.1976 28.6523 40.1952 28.4198 40.1952 28.1751ZM34.1944 49.6751C37.8426 49.6751 40.1858 49.1195 40.1858 47.175C40.1858 47.0391 40.1882 46.9099 40.1906 46.7872L40.1906 46.7871L40.1906 46.787C40.2214 45.1556 40.2305 44.6751 34.1944 44.6751C28.1588 44.675 28.1679 45.1555 28.1988 46.7865C28.2011 46.9094 28.2036 47.0388 28.2036 47.175C28.2036 49.1195 30.5463 49.6751 34.1944 49.6751ZM39.1947 34.0893C39.1947 33.824 39.3 33.5697 39.4876 33.3822L41.1947 31.6751L41.1947 44.6751L39.7475 43.9514C39.4087 43.7821 39.1947 43.4358 39.1947 43.057L39.1947 34.0893ZM28.9018 33.3822C29.0893 33.5697 29.1947 33.824 29.1947 34.0893L29.1947 43.057C29.1947 43.4358 28.9807 43.7821 28.6419 43.9514L27.1947 44.675L27.1947 31.675L28.9018 33.3822Z" />
                          <path d="M13.4162 8.23037C13.2043 8.0184 13.2256 7.66789 13.4638 7.48594C25.6598 -1.82865 42.6906 -1.82865 54.8866 7.48594C55.1248 7.66789 55.1461 8.0184 54.9342 8.23038C54.755 8.40958 54.4695 8.42567 54.268 8.27194C42.4339 -0.757313 25.9165 -0.757313 14.0824 8.27194C13.8809 8.42566 13.5954 8.40957 13.4162 8.23037ZM7.73026 13.9164C7.51828 13.7044 7.16777 13.7257 6.98582 13.964C-2.32865 26.1599 -2.32861 43.1907 6.98594 55.3866C7.16789 55.6248 7.5184 55.6461 7.73037 55.4342C7.90957 55.255 7.92567 54.9695 7.77194 54.768C-1.25728 42.934 -1.25731 26.4166 7.77182 14.5825C7.92554 14.3811 7.90945 14.0956 7.73026 13.9164ZM54.934 61.1201C54.7548 60.9409 54.4693 60.9249 54.2678 61.0786C42.4338 70.1077 25.9166 70.1077 14.0826 61.0786C13.8811 60.9249 13.5956 60.9409 13.4164 61.1201C13.2044 61.3321 13.2257 61.6826 13.464 61.8646C25.6599 71.179 42.6905 71.179 54.8864 61.8646C55.1247 61.6826 55.146 61.3321 54.934 61.1201ZM60.62 55.4342C60.832 55.6461 61.1825 55.6248 61.3644 55.3866C70.679 43.1907 70.679 26.1599 61.3646 13.964C61.1826 13.7257 60.8321 13.7044 60.6201 13.9164C60.4409 14.0956 60.4249 14.3811 60.5786 14.5826C69.6077 26.4166 69.6077 42.934 60.5785 54.768C60.4247 54.9695 60.4408 55.255 60.62 55.4342Z" />
                          <path d="M19.5875 16.0599C19.3753 15.8477 19.3963 15.497 19.636 15.3166C28.2215 8.85154 40.1288 8.85151 48.7144 15.3165C48.9541 15.4969 48.975 15.8476 48.7629 16.0598C48.5838 16.2389 48.2988 16.2552 48.0963 16.103C39.8725 9.92267 28.4778 9.9227 20.2541 16.1031C20.0516 16.2553 19.7666 16.239 19.5875 16.0599ZM15.5599 20.0874C15.3477 19.8753 14.997 19.8963 14.8166 20.1359C8.35154 28.7215 8.35151 40.6288 14.8165 49.2144C14.9969 49.4541 15.3476 49.475 15.5598 49.2629C15.7389 49.0838 15.7552 48.7988 15.603 48.5963C9.42267 40.3725 9.4227 28.9778 15.6031 20.7541C15.7553 20.5516 15.739 20.2666 15.5599 20.0874ZM48.763 53.2906C48.5839 53.1115 48.2989 53.0952 48.0964 53.2474C39.8726 59.4278 28.4777 59.4278 20.254 53.2473C20.0515 53.0951 19.7664 53.1114 19.5873 53.2905C19.3752 53.5027 19.3962 53.8533 19.6358 54.0338C28.2214 60.499 40.1289 60.499 48.7145 54.0339C48.9542 53.8534 48.9752 53.5028 48.763 53.2906ZM52.7906 49.263C53.0028 49.4752 53.3534 49.4542 53.5339 49.2145C59.999 40.6289 59.999 28.7214 53.5338 20.1358C53.3533 19.8962 53.0027 19.8752 52.7905 20.0873C52.6114 20.2664 52.5951 20.5515 52.7473 20.754C58.9278 28.9777 58.9278 40.3726 52.7474 48.5964C52.5952 48.7989 52.6115 49.0839 52.7906 49.263Z" />
                          <path d="M16.8517 12.5891C16.6396 12.377 16.6608 12.0264 16.8997 11.8452C27.0859 4.11703 41.2645 4.11703 51.4508 11.8452C51.6897 12.0264 51.7108 12.377 51.4988 12.5891C51.3196 12.7683 51.0344 12.7844 50.8324 12.6314C41.008 5.18829 27.3424 5.18829 17.5181 12.6314C17.3161 12.7844 17.0308 12.7683 16.8517 12.5891ZM12.0889 17.3519C11.8769 17.1398 11.5263 17.161 11.345 17.3999C3.61703 27.5861 3.61708 41.7646 11.3452 51.9508C11.5264 52.1897 11.877 52.2108 12.0891 51.9988C12.2683 51.8196 12.2844 51.5344 12.1314 51.3324C4.68834 41.5081 4.68829 27.8426 12.1313 18.0183C12.2843 17.8163 12.2681 17.531 12.0889 17.3519ZM51.4986 56.7615C51.3194 56.5824 51.0342 56.5662 50.8322 56.7192C41.0079 64.1621 27.3426 64.1621 17.5183 56.7192C17.3163 56.5662 17.031 56.5824 16.8519 56.7615C16.6398 56.9736 16.661 57.3242 16.8999 57.5054C27.0861 65.2334 41.2644 65.2334 51.4506 57.5054C51.6895 57.3242 51.7106 56.9736 51.4986 56.7615ZM56.2614 51.9988C56.4734 52.2108 56.824 52.1897 57.0053 51.9508C64.7334 41.7646 64.7334 27.5861 57.0054 17.3999C56.8242 17.161 56.4736 17.1398 56.2615 17.3519C56.0824 17.531 56.0662 17.8163 56.2192 18.0183C63.6622 27.8426 63.6621 41.5081 56.219 51.3324C56.066 51.5344 56.0822 51.8196 56.2614 51.9988Z" />
                        </svg>
					`
        }
      ]}
    />
  )
};

export default Sprite;
