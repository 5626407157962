import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Slider from "../../components/Slider/Slider";
import SwiperCore, {Pagination, Scrollbar, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "./Good.scss";
import PictureSlide from "../../components/Slider/Slides/PictureSlide/PictureSlide";
import DefaultSlide from "../../components/Slider/Slides/DefaultSlide/DefaultSlide";
import {ToggleSlide} from "../../components/Slider/Slides/ToggleSlide/ToggleSlide";
import Controls from "../../components/Slider/Controls/Controls";
import { LanguageContext } from '../../contexts/LanguageContext';

const Good = ({
    title,
    subtitle,
    slides,
    dir,
    className,
    sliderClassName,
    slideType,
    slideClassName,
    changeTitle,
    id
}) => {

    SwiperCore.use([Scrollbar, Pagination, Navigation]);
    const [init, reinit] = React.useState(true);
    const [isMobile, setMobile] = React.useState(window.innerWidth < 768);
    const [dynamicTitle, setTitle] = React.useState(slides[0].title);
    const [dynamicSubtitle, setSubtitle] = React.useState(slides[0].subtitle);
    const [dynamicHeight, setHeight] = React.useState("auto");

    const renderSlides = (slide, index, length, callback) => {
        switch (slide.slideType || slideType) {
            case "PictureSlide":
                return <PictureSlide className={slideClassName} slideInfo={slide} index={index} length={length}
                                     useCounter={true}/>;
                break;
            case "ToggleSlide":
                return <ToggleSlide slideInfo={slide} index={index} length={length} className={slideClassName}
                                    useCounter={true}/>;
                break;
            default:
                return <DefaultSlide className={slideClassName} slideInfo={slide} length={length} index={index}/>;
                break;
        }
    }

    React.useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    React.useEffect(() => {
        reinit(false);
        setTimeout(() => {
            reinit(true)
        }, 1);
    }, [dir]);

    const onResize = (event) => {
        setMobile(window.innerWidth < 768);
        reinit(false);
        setTimeout(() => {
            reinit(true);
        }, 1);
    }

    const { lang } = useContext(LanguageContext);

    return (
        <section className={`pp-section pp-section-good ${className || ""}`} id={id || ''}>
            <div className={"container"}>
                {!changeTitle
                    ? <TitleBlock
                        height={dynamicHeight}
                        title={title}
                        subtitle={subtitle}
                      />
                    : <TitleBlock
                        height={dynamicHeight}
                        title={dynamicTitle[lang] || dynamicTitle}
                        subtitle={dynamicSubtitle[lang] || dynamicSubtitle}
                      />
                }
                {/*<Slider slides={slides}*/}
                {/*        className={`visible ${sliderClassName || ""}`}*/}
                {/*        dir={dir}*/}
                {/*        forceOnChange={true}*/}
                {/*        onChange={(e)=>{*/}
                {/*            setTitle(slides[e].title);*/}
                {/*            setSubtitle(slides[e].subtitle);*/}
                {/*        }}*/}
                {/*        renderSlide={renderSlides}*/}
                {/*        config={{slidesPerView: 1, spaceBetween: 40}}/>*/}
                {init ? <Swiper
                    slidesPerView={1}
                    className={`visible ${slideClassName || ""}`}
                    spaceBetween={40}
                    navigation={{
                        nextEl: '.swiper-good-next',
                        prevEl: '.swiper-good-prev'
                    }}
                    onSlideChange={(e) => {
                        setTitle(slides[e.activeIndex].title);
                        setSubtitle(slides[e.activeIndex].subtitle);
                    }}
                    scrollbar={{
                        draggable: true,
                        // dragSize: 82,
                        snapOnRelease: true,
                        dragClass: "handle"
                    }}
                    dir={dir}
                >
                    <Controls className={'good-class'}
                              direction={dir}
                              nextEl={"swiper-button-next swiper-good-next"}
                              prevEl={"swiper-button-prev swiper-good-prev"}
                              prevElSvg={'arrow-reverse'}
                              nextElSvg={'arrow'}
                              useWrapper={false}/>
                    {slides.map((slide, index) => {
                        return (
                            <SwiperSlide key={index}>
                                {renderSlides(slide, index, slides.length)}
                            </SwiperSlide>
                        )
                    })}
                </Swiper> : null}
            </div>
        </section>
    );

};

Good.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    slides: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Good;
