import React from 'react';
import LanguageProvider from "./contexts/LanguageContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import Main from "./pages/Main/Main";
import Regions from "./pages/Regions/Regions";

import './App.scss';
import ThankYou from "./pages/ThankYou/ThankYou";
import LegalDisclaimer from './pages/MainDisclaimers/MainDisclaimers';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.recaptcha = React.createRef();
  }

  render() {
    const allowedSubRegions = ['emirates_golf_club', 'dubai_creek_golf', 'jumeirah_golf_estates']
    let queryArray =(window.location.search).substr(1).split('=')
    if (queryArray[0] === 'utm_campaign' && allowedSubRegions.includes(queryArray[1])){
      if (window.location.pathname.includes('/en')) {
        window.location = `${process.env.REACT_APP_URL}UAE/en/${queryArray[1]}`
      } else {
        window.location = `${process.env.REACT_APP_URL}UAE/${queryArray[1]}`
      }
    }

    return (
      <LanguageProvider>
        <Router>
          <Switch>
            <Route exact path={`${process.env.REACT_APP_URL}:region/:lang?/thank-you`} component={ThankYou} />
            <Route exact path={`${process.env.REACT_APP_URL}:region/:lang?/legal-disclaimers`} component={LegalDisclaimer} />
            <Route exact path={`${process.env.REACT_APP_URL}:region/:lang?`} component={Main} />
            <Route exact path={`${process.env.REACT_APP_URL}:region/:lang/:subregion`} component={Main} />
            <Route exact path={`${process.env.REACT_APP_URL}`} component={Regions} />
          </Switch>
        </Router>
      </LanguageProvider>
    );
  }
}

export default App;
