import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Image from "../UI/Images/Image";
import "./Region.scss";

const regions = ["UAE", "Qatar", "Morocco", "Egypt", "Mauritius", "Oman", "Bahrain", "Qatar", "Ivory Coast", "Nigeria", "Lebanon", 'Kuwait',"South-Africa", "Jordan"];

const Region = ({ region }) => {
  const imageName = regions.includes(region) ? region : "sa";

  return (
    <Link to={`/${region}${(region === 'South-Africa') ? '/en' : ''}`.replace(" ","-")} className={"region"}>
      <Image src={`regions/${imageName.toLowerCase().replace(" ","_")}.png`}
             alt={region} />
      <p>{region}</p>
    </Link>
  );

};

Region.propTypes = {};

export default Region;
