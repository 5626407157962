import React from 'react';
import PropTypes from 'prop-types';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";

import "./mainFeatures.scss";
import {SafeCare} from "../SafeCare/SafeCare";
import {TileBlock} from "../../components/TileBlock/TileBlock";


export const MainFeatures = ({ title, slides, smallSlides, textAbove, dir, className, id, autoAlign}) => {

    return (
        <section id={id} className={`pp-section pp-section-mf ${className || ""}`}>
            <div className={"container"}>
                <TileBlock
                    key={dir}
                    dir={dir}
                    slides={smallSlides.safety}
                    className={"white height"}
                    title={textAbove.safety}
                />
            </div>
        </section>
    );

};

MainFeatures.propTypes = {
    title: PropTypes.string,
    slides: PropTypes.object,
    smallSlides: PropTypes.arrayOf(PropTypes.object),
};
