import React from 'react';
import PropTypes from 'prop-types';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Button from "../../components/UI/Button/Button";
import context from "react-router/modules/RouterContext";
import { LanguageContext } from "../../contexts/LanguageContext";

const LearnMore = ({title, subtitle, btns, link, region, onFileDownload}) => {

    if (region === 'aloula') {
        btns = [btns[1]]
    }
    return (
        <LanguageContext.Consumer>
            {context => {
                return (
                    <section className={"pp-section pp-section-learn-more"}>
                        <div className={"container"}>
                            <TitleBlock title={title}
                                        subtitle={subtitle}
                                        className={"center"}/>
                            <div className={"btns"}>
                                {btns.map(btn => {
                                  if (btn.label[region]) {
                                    return (
                                      <Button key={btn.label[region]}
                                              text={btn.label[region]}
                                              className={btn.className}
                                              isFile={btn.isFile}
                                              onClick={btn.isFile ? onFileDownload : ()=>{}}
                                              target={'_blank'}
                                              // download={btn.download}
                                              // { ...btn.targetBlank && { target: '_blank' } }
                                              link={typeof btn.link === 'object' ? context.getTranslate(btn.link[region]) : btn.link || context.getTranslate(link)}
                                      />
                                    );
                                  }
                                })}
                            </div>
                        </div>
                    </section>
                );
            }}
        </LanguageContext.Consumer>
    )

};

LearnMore.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  btns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LearnMore;
