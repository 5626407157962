import React from 'react';
import {TileBlock} from "../../components/TileBlock/TileBlock";

import "./tileContainer.scss";

export const TileContainer = ({smallSlides, textAbove, className, background, dir, id}) => {
  return (
    <div id={id} className={`pp-section pp-section-tc ${background} ${id}`}>
      <div className={"container"}>
      <TileBlock
        key={dir}
        dir={dir}
        slides={smallSlides}
        title={textAbove}
        className={className}
      />
      </div>
    </div>
  );
};
