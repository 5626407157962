import React from 'react';
import Parser from "html-react-parser";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Controller, Navigation, Pagination} from 'swiper';

import Image from "../UI/Images/Image";
import {LanguageContext} from "../../contexts/LanguageContext";

import 'swiper/swiper.scss'
import './tileBlock.scss';
import TitleBlock from "../UI/TitleBlock/TitleBlock";
import Slider from "../Slider/Slider";

SwiperCore.use([Navigation, Pagination, Controller]);

export const TileBlock = ({slides, title, className, dir, autoAlign}) => {

    const [breakPoint, setBreakPoint] = React.useState(3);
    const [dire, setDire] = React.useState(dir);
    const [init, reinit] = React.useState(true);

    const check = React.useRef()

    const [controlledSwiper, setControlledSwiper] = React.useState(null);

        React.useEffect(() => {
            if (window.innerWidth < 1024)  {
                setBreakPoint(3)
            }

        })

        const params = {
            onInit: (swiper) => {
                this.swiper = swiper
            }
        }

        React.useEffect(() => {
            if (window.innerWidth < 1024) {
                setDire(dir)
                check.current.swiper.rtl = dir == "rtl"
                setControlledSwiper(params)
            }
        }, [dir])

        React.useEffect(() => {
            reinit(false);
            setTimeout(() => {
                reinit(true)
            }, 1);
        }, [dir]);

    return (
        <LanguageContext.Consumer>
            {(context) => {

                context.checkLang();
                const { lang } = context;

                return (
                    <div className={`block-tiles ${dir}`}>
                        {title && <TitleBlock title={title}/>}
                        {window.innerWidth > 1034 ?
                            <Slider slides={slides}
                                    className={"visible"}
                                    dir={dir}
                                    config={{slidesPerView: 2, spaceBetween: 40}}/>
                            : init && <Swiper ref={check} slidesPerView={window.innerWidth < 1024 ? 1 : 3}
                                              pagination
                                              controller={{control: controlledSwiper}}
                                              spaceBetween={24}>
                            {window.innerWidth > 1024
                                ? slides.map(slide => <div className={`tile`}>
                                    <Image src={slide.image_min ? slide.image_min : slide.image}/>
                                    <div className={"title"}>{context.getTranslate(slide.title[lang] || slide.title)}</div>
                                    <div className={"subtitle"}>{context.getTranslate(slide.subtitle[lang] || slide.subtitle)}</div>
                                </div>)
                                :
                                slides.map((slide, index) => <SwiperSlide className={`${className}`}>
                                        <div className={"slide_wrapper"}>
                                            <Image src={slide.image_min ? slide.image_min : slide.image}/>
                                            <div className={"title"}>{context.getTranslate(slide.title[lang] || slide.title)}</div>
                                            {slide.subtitle &&
                                            <div
                                                className={"subtitle"}>{context.getTranslate(slide.subtitle[lang] || slide.subtitle)}</div>}
                                        </div>
                                    </SwiperSlide>
                                )}
                        </Swiper>
                        }
                    </div>
                );
            }}
        </LanguageContext.Consumer>
    )
};
